import React, { useContext, useEffect, useState } from "react";
import { AccountContext } from "../features/cognito/Account";
import { SettingsHook } from "../features/cognito/SettingsHook";
import { WebsocketContext } from "../features/mqtt/WebsocketContext";
//Simport { useTranslation } from "react-i18next";
import RecommendationsComponent from "./RecommendationsComponent";
export default function RecommendationsOverlay(props) {
	const [isReady, val, setVal, recommendations, setRecommendations] = useContext(WebsocketContext);
	const { getSession } = useContext(AccountContext);
	const [
		language,
		setlanguage,
		showRecommendations,
		setShowRecommendations,
		recommendationsTab,
		setRecommendationsTab,
		streamingMode,
		setStreamingMode,
	] = useContext(SettingsHook);
	//console.log("recommendations: ", recommendations);
	//const { t } = useTranslation();
	var recommendationItems = [];
	if (recommendations) {
		//	console.log(			"window.sessionStorage.getItem(recommendations): ",			window.sessionStorage.getItem("recommendations")		);
		recommendationItems = recommendations;
		//recommendationItems = JSON.parse(window.sessionStorage.getItem("recommendations"));
		//console.log("RecommendationsObject: ", recommendationItems);
	}

	//recommendationList = ["00007"];
	//recommendationList = JSON.parse(window.sessionStorage.getItem("recommendations"));
	//console.log("RecommendationsObject: ", recommendationList);
	//window.sessionStorage.removeItem("recommendations");

	//console.log(overlayDiv);
	useEffect(() => {
		getSession().then(() => {
			//setLoggedIn(true);

			function myFunction() {
				var overlayDiv = document.getElementById("overlay");
				if (overlayDiv.style.display === "block") {
					overlayDiv.style.display = "none";
					setRecommendations(null);
				}
			}

			document.getElementById("exitButton").addEventListener("click", myFunction);

			//	let button_recommendation = document.getElementById("button_recommendation");
			//	button_recommendation.remove();
		});
	});

	return (
		<div>
			<div id="overlayHeader">
				<div>
					<h2> Recommendations</h2>
				</div>
				<div>
					<button id="exitButton" type="button">
						X
					</button>
				</div>
			</div>
			<div className="recommendations-overlay">
				{recommendations && !recommendationsTab ? (
					<RecommendationsComponent recommendationList={recommendations}></RecommendationsComponent>
				) : (
					<></>
				)}
			</div>
		</div>
	);
}
