import React from "react";
import { startApplicationOnServer, appList } from "../features/api-gateway/aws-apigw";

//export default class AppComponents extends React.Component {
export default function AppComponents(props) {
	//console.log("AppID: ", props.id);

	function stopOverlay() {
		var overlayDiv = document.getElementById("streamOverlay");

		if (overlayDiv.style.display === "block") {
			overlayDiv.style.display = "none";
		}
	}

	function startStream(id) {
		var serverRes;
		startApplicationOnServer(id).then((res) => {
			// TODO: handling of statuscode res.status
			serverRes = res.data;
			console.log(res.data);
			console.log("Stream started: ", Number(id));
			console.log(serverRes);
			console.log(JSON.stringify(serverRes));
			var overlayDiv = document.getElementById("streamOverlay");
			overlayDiv.style.display = "block";

			try {
				window.startStream(serverRes).then((res) => {
					console.log("startStream executed by client");
				});
			} catch (error) {
				console.error(error);
				//TODO: handle error for non client usage
			}
			window.setTimeout(stopOverlay, 15000);
		});
		return serverRes;
	}

	//console.warn(this.props)
	return (
		<div>
			<img
				className="wideImage"
				src={props.img}
				alt={props.id}
				onClick={() => startStream(props.id)}
			></img>
		</div>
	);
}

//const rootElement = document.getElementById("root");
//ReactDOM.render(<AppComponents />, rootElement);
