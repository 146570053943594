import React, { createContext, useContext, useState } from "react";
import { CognitoUser, AuthenticationDetails, CognitoUserPool } from "amazon-cognito-identity-js";
import Pool from "./UserPool";
import { userdata, appList } from "../api-gateway/aws-apigw";

const AccountContext = createContext();

const Account = (props) => {
	const [showRecommendation, setShowRecommendations] = useState(true);

	const setRecommendationOption = (option) => {
		setShowRecommendations(option);
	};

	const getRecommendationOption = () => {
		return showRecommendation;
	};

	const getUserData = async () => {
		if (!window.localStorage.getItem("userData")) {
			try {
				const response = userdata();
				const accessToken = window.sessionStorage.getItem("accessToken");
				/* 
            const config = {
                headers: { 'Authorization': `Bearer ${accessToken}`    }
            };
    
            
            const response = await axios.get( 
              'sofdcar_userdata',
              config
            ); */
				//console.log("Response Userdata: ", response);

				/*   const responsePost = await  axios.post(
                '/userdatavbyid',
                config,
                {'id': window.sessionStorage.getItem("userId")}
                );
            console.log("Response Post: ", responsePost);
           */ //const resp = await axios.get('/sofdcar_userdata');
				//console.log(resp.data);
				//const statuscode =  resp.status; // API without token
				const statuscode = response.status;

				if (statuscode === 200) {
					console.log("Response data: ", response.data.body.Item);
					//const    userdataGet = resp.data.body.Item;  // API without token
					//const    userdataGet = response.data.body.Item; // API without token
					const userdataGet = response.data.body.Item;
					window.localStorage.setItem("userData", JSON.stringify(userdataGet));
					console.log("Fävoriten: ", JSON.stringify(userdataGet).Favorites);
					window.sessionStorage.setItem("favorites", JSON.stringify(userdataGet).Favorites);
					window.sessionStorage.setItem(
						"ProfileIcon",
						JSON.stringify(userdataGet).ProfileImageBaseSmall
					);
					//  console.log("userdata window.sessionStorage: ", window.sessionStorage.getItem("userData"));
				}
			} catch (err) {
				// Handle Error Here
				console.error(err);
			}
		}
		return "200";
	};

	const getAppData = async () => {
		try {
			const resp = appList();
			// console.log(resp.data);
			const statuscode = resp.status;

			if (statuscode === 200) {
				//console.log("Response data: ", resp.data.body.Item)
				const appdata = resp.data.body;
				window.sessionStorage.setItem("appList", JSON.stringify(appdata));

				// console.log("Applist window.sessionStorage: ", JSON.parse(window.sessionStorage.getItem("appList")));
			}
		} catch (err) {
			// Handle Error Here
			console.error(err);
		}
	};

	const getSession = async () => {
		return await new Promise((resolve, reject) => {
			const user = Pool.getCurrentUser();
			//console.log(user);

			//console.log(window.sessionStorage.getItem('idToken'));

			if (user) {
				user.getSession((err, session) => {
					if (err) {
						reject();
					} else {
						//  console.log("Sessiondetails ID Token: ",  session.idToken);
						//  console.log("Sessiondetails ID Token: ",  session.refreshToken);

						window.sessionStorage.setItem("idToken", session.idToken.jwtToken);
						//console.log("idToken ls: ", window.sessionStorage.getItem('idToken'));

						window.sessionStorage.setItem("refreshToken", session.refreshToken.jwtToken);
						window.sessionStorage.setItem("accessToken", session.accessToken.jwtToken);
						window.sessionStorage.setItem("loggedIn", true);
						window.sessionStorage.setItem("userId", session.idToken.payload.sub);
						getUserData();
						//getAppData();
						/*  const response =    axios.get('/sofdcar_userdata');
                
            
                //console.log("Response",response);
                const statuscode =  response.status;
                //console.log("StatusCode",statuscode);
                 
                if(statuscode ===200){
                 //   console.log("Test",response.data.body);
                const    userdata = response.data.body.Item;
                //setApplist( applist => [...applist, appdata]);
                //setApplist(appdata);
               window.sessionStorage.setItem('userData',userdata);
               window.sessionStorage.setItem('userImg',userdata.profileImageBase);

               
                  console.log("userdata window.sessionStorage Image: ", window.sessionStorage.getItem("userImg"));

                //console.log("Applist: ",applist); 
    
            };*/
						resolve(session);
					}
				});
			}
		});
	};
	const authenticate = async (Username, Password) => {
		return await new Promise((resolve, reject) => {
			const user = new CognitoUser({
				Username,
				Pool,
			});

			const authDetails = new AuthenticationDetails({ Username, Password });

			user.authenticateUser(authDetails, {
				onSuccess: (data) => {
					//		console.log("onSucess: ", data);
					resolve(data);
				},
				onFailure: (err) => {
					console.error("onFailure: ", err);
					reject(err);
				},
				newPasswordRequired: (data) => {
					console.log("newPasswordRequired: ", data);
					resolve(data);
				},
			});
		});
	};

	const logOut = async () => {
		const user = Pool.getCurrentUser();
		if (user) {
			user.signOut();
			window.sessionStorage.clear();
			window.sessionStorage.removeItem("idToken");
			window.sessionStorage.removeItem("refreshToken");
			window.sessionStorage.removeItem("accessToken");
			window.sessionStorage.removeItem("loggedIn");
			window.sessionStorage.removeItem("userId");
			window.localStorage.removeItem("userData");
			window.sessionStorage.removeItem("favorites");
			window.sessionStorage.removeItem("ProfileImageBaseSmall");

			localStorage.removeItem("Apps");
		}
	};

	return (
		<AccountContext.Provider
			value={{
				authenticate,
				getSession,
				logOut,
				setRecommendationOption,
				getRecommendationOption,
			}}
		>
			{props.children}
		</AccountContext.Provider>
	);
};

export { Account, AccountContext };
