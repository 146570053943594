import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "../features/cognito/Account";
import qrcode from "../images/qrcode/qrcode_sofdcar-de.png";
import * as rssParser from "react-native-rss-parser";
import { ConsoleLogger } from "@aws-amplify/core";
import RssItem from "./RssItem";
import { useTranslation, initReactI18next } from "react-i18next";

export default function Community() {
	const { t } = useTranslation();
	const [loggedIn, setLoggedIn] = useState(false);
	const { getSession } = useContext(AccountContext);
	const [showMore, setShowMore] = useState(false);
	var rssfeed = {};
	var datefeed = {};
	const [rssFeed, setRssFeed] = useState(rssfeed);
	const [dateFeed, setDateFeed] = useState(datefeed);
	const text = "";

	const pics = "";
	var rssurl = window.location.origin + "/rss/news.rss";
	var datesurl = window.location.origin + "/rss/dates.rss";

	useEffect(() => {
		async function getRssFeed() {
			const response = await fetch(rssurl);
			const responseText = await response.text();
			const rss = await rssParser.parse(responseText);
			//console.log("Fetch: ", rss);

			if (rssFeed != rss) setRssFeed(rss);
			//console.log("Items: ", rssFeed.description);
		}
		async function getDateFeed() {
			const response = await fetch(datesurl);
			const responseText = await response.text();
			const rss = await rssParser.parse(responseText);
			//console.log("Fetch: ", rss);

			if (dateFeed != rss) setDateFeed(rss);
			//console.log("Items: ", rssFeed.description);
		}

		if (Object.keys(rssFeed).length === 0) {
			getRssFeed();
			//console.log("fetch: ");
		}
		if (Object.keys(dateFeed).length === 0) {
			getDateFeed();
			//console.log("fetch: ");
		}
		/*console.log(rssFeed.title);
            console.log(rssFeed.description);
            console.log(rssFeed.content);*/
	});

	return (
		//{ loggedIn ?  <h1>SofDCar</h1> : <div>Login</div > }
		<div className="community-container-content">
			<div className="community-container-content-left">
				<div className="community-areas-sofdcar">
					<h3>SofDcar</h3>
					{t("project_description")}
				</div>

				<div className="community-areas-qr">
					<img id="community-qrcode" src={qrcode} alt="QR-Code" />
				</div>
			</div>

			<div className="community-container-content-right">
				<div className="community-areas-dates">
					<h3>News / Termine</h3>
					{Object.keys(dateFeed).length != 0 ? (
						dateFeed.items.map((item) => <RssItem key={item["id"]} item={item} />)
					) : (
						<div></div>
					)}
				</div>
				<div className="community-areas-updatenews">
					{rssFeed.title}
					{Object.keys(rssFeed).length != 0 ? (
						rssFeed.items.map((item) => <RssItem key={item["id"]} item={item} />)
					) : (
						<div></div>
					)}
				</div>
			</div>
		</div>
	);
}
