import React from "react";
import car0 from "./images/car_battery/car0.png";
import car1 from "./images/car_battery/car1.png";
import car2 from "./images/car_battery/car2.png";
import car3 from "./images/car_battery/car3.png";
import car4 from "./images/car_battery/car4.png";
import car5 from "./images/car_battery/car5.png";
import car6 from "./images/car_battery/car6.png";
import car7 from "./images/car_battery/car7.png";
import car8 from "./images/car_battery/car8.png";
import car9 from "./images/car_battery/car9.png";
import car10 from "./images/car_battery/car10.png";

export default function carBattery(props) {
	const images = [car0, car1, car2, car3, car4, car5, car6, car7, car8, car9, car10];
	const code = props.code;
	return (
		<div className="widget">
			<img className="batteryCar" src={images[code]} alt="cleaning images" />
		</div>
	);
}
