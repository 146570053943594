import React, { useState } from "react";

export default function RssItem(props) {
	const [showMore, setShowMore] = useState(false);
	//console.log(props)
	return (
		<div>
			{/*<p>{props.item.title}</p>
			<p>{props.item.description}</p>*/}

			<p>
				{props.item.title}{" "}
				<button className="community-button-mehranzeigen" onClick={() => setShowMore(!showMore)}>
					{showMore ? "↓" : "↑"}
				</button>
			</p>
			{showMore ? <p>{props.item.description}</p> : ""}
		</div>
	);
}
