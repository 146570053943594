import { createContext, useState } from "react";

const LoggedInContext = createContext(false, null, () => {});

const LoggedInContextProvider = ({ children }) => {
	const [loggedIn, setLoggedIn] = useState(false);

	const ret = [loggedIn, setLoggedIn];

	return <LoggedInContext.Provider value={ret}>{children} </LoggedInContext.Provider>;
};
export { LoggedInContextProvider, LoggedInContext };
