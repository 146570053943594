import React, { Component } from "react";

function updateDateTime() {
	var showdate = new Date();
	return `${showdate.toDateString()} - > ${showdate.toLocaleTimeString([], {
		hour: "2-digit",
		minute: "2-digit",
	})}`;
}
function updateDates() {
	var showdate = new Date();
	return `${showdate.toLocaleDateString()}`;
}
function updateTime() {
	var showdate = new Date();
	return ` ${showdate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`;
}

class Clock extends Component {
	constructor(props) {
		super(props);
		this.state = { currentCount: updateDateTime() };
	}
	timer() {
		this.setState({
			currentDate: updateDates(),
			currentTime: updateTime(),
		});
	}
	componentDidMount() {
		this.intervalId = setInterval(this.timer.bind(this), 1000);
	}
	componentWillUnmount() {
		clearInterval(this.intervalId);
	}
	render() {
		return (
			<div className="clock">
				{this.state.currentDate} <br></br> {this.state.currentTime}
			</div>
		);
	}
}

export default Clock;
