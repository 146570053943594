import React from "react";
import AppComponents from "./AppComponents";

export default function HomeComponent(props) {
	//console.warn(this.props)
	//console.log("HC favoriten: ", props.favorites);
	//var appList = JSON.parse(window.sessionStorage.getItem("Apps"));
	var favorites = [];
	props.favorites.forEach((element) => {
		favorites.push(element);
		//console.log("Path: ", appList[element].Path);
	});
	//console.log("Favorites: ", favorites);
	//console.log(props.favorites);
	return favorites.map((favorite) => (
		//               apps.indexOf(app)
		<AppComponents
			img={
				favorites.indexOf(favorite) === 0
					? require("../images/favorites/" + favorite + "_wide.png")
					: require("../images/favorites/" + favorite + ".png")
			}
			id={favorite}
			name={favorite}
			key={favorite}
		/>
	));
}
