import React, { useState, useEffect, useContext } from "react"; //useContext,
//import AppComponents from "./AppComponents";
import HomeComponent from "./HomeComponent";
//import { AccountContext } from "../features/cognito/Account";
import { useNavigate } from "react-router-dom";

import { LoggedInContext } from "../features/cognito/loggedInHook";
import { AccountContext } from "../features/cognito/Account";
export default function Home() {
	const [loggedIn, setLoggedIn] = useContext(LoggedInContext);
	const { getSession } = useContext(AccountContext);
	//console.log(loggedIn);
	let navigate = useNavigate();
	var favorites = JSON.parse(window.sessionStorage.getItem("favorites"));
	//console.log(favorites);

	useEffect(() => {
		if (!loggedIn || !favorites) {
			navigate("/login");
			//setLoggedIn(true);
		}
		getSession().then(() => {
			//setLoggedIn(true);
		});
	});
	//console.log(JSON.parse(window.sessionStorage.getItem("userData")).favorites);

	function loginPage() {
		//console.log("Go to Loginpage");
		navigate("/login");
	}
	//console.log("Favoriten null: ",favorites==undefined);

	//console.log("Not LoggedIN: ",!loggedIn);

	//console.log("Not LoggedIN + Favoriten null: ",(!loggedIn & favorites==undefined) );

	return (
		<div className="subgrid-container">
			{loggedIn && favorites !== undefined ? (
				<HomeComponent favorites={favorites}>{favorites}</HomeComponent>
			) : (
				<div>
					{" "}
					Hier könnte ein Demo-Video sein das gestartet werden kann wenn der Nutzer die App noch
					nicht kennt in der alles erklärt wird. Sonst direkt einloggen <br></br>{" "}
					<button onClick={loginPage} className="glow-on-hover">
						{" "}
						Login
					</button>
				</div>
			)}
		</div>
	);
}

//<HomeComponent favorites={favorites} ></HomeComponent>
