import React from "react";
import { NavLink } from "react-router-dom";
import home from "./images/home.png";
import logout from "./images/logout.png";

export default function FooterItem(props) {
	const symbols = {
		home: { name: "home", hasImage: home },
		logout: { name: "logout", hasImage: logout },
	};

	return (
		<NavLink id={`${props.id}_navLink`} exact="true" to={props.destination}>
			<div className={`${props.classes} footer_buttons`} id={props.id} >
					<div className="button_texts">
						{props.hasImage ? <img id={`${props.id}_icon`} alt={props.text} src={props.hasImage} /> : props.text}
					</div>
			</div>
		</NavLink>
	);
}
