import React from "react";
import controller0 from "./images/controller_battery/controller0.png";
import controller1 from "./images/controller_battery/controller1.png";
import controller2 from "./images/controller_battery/controller2.png";
import controller3 from "./images/controller_battery/controller3.png";
import controller4 from "./images/controller_battery/controller4.png";
import controller5 from "./images/controller_battery/controller5.png";
import controller6 from "./images/controller_battery/controller6.png";
import controller7 from "./images/controller_battery/controller7.png";
import controller8 from "./images/controller_battery/controller8.png";
import controller9 from "./images/controller_battery/controller9.png";
import controller10 from "./images/controller_battery/controller10.png";

export default function controllerBattery(props) {
	const images = [
		controller0,
		controller1,
		controller2,
		controller3,
		controller4,
		controller5,
		controller6,
		controller7,
		controller8,
		controller9,
		controller10,
	];
	const code = props.code;
	return (
		<div className="widget">
			<img className="batteryController" src={images[code]} alt="cleaning images" />
		</div>
	);
}
