const axios = require("axios").default;

var baseURL = "https://v7o4hiwjig.execute-api.eu-central-1.amazonaws.com/api";

export default axios.create({
	baseURL: "https://v7o4hiwjig.execute-api.eu-central-1.amazonaws.com/api",
	timeout: 5000,
});

export async function userdata() {
	//console.log("Response USERDATA");
	const accessToken = window.sessionStorage.getItem("accessToken");
	//console.log("LS userId: ",window.sessionStorage.getItem("userId"))
	const config = {
		headers: { Authorization: `Bearer ${accessToken}` },
	};
	const response = await axios.post(
		baseURL + "/userdatavbyid",
		{ id: window.sessionStorage.getItem("userId") },
		config
	);
	//console.log("Response Post (userdata): ", response.data);
	window.sessionStorage.setItem("userData", JSON.stringify(response.data.body.Item));
	window.sessionStorage.setItem("favorites", JSON.stringify(response.data.body.Item.Favorites));
	//console.log("profileImageLogin: ", JSON.stringify(response.data.body.Item.ProfileImageBaseSmall));
	window.localStorage.setItem(
		"ProfileImageBaseSmall",
		JSON.stringify(response.data.body.Item.ProfileImageBaseSmall)
	);
	return response.data;
}

export async function stopStreamingbackend(appid) {
	const accessToken = window.sessionStorage.getItem("accessToken");
	//      console.log("accessToken window.sessionStorage:", accessToken);
	const config = {
		headers: { Authorization: `Bearer ${accessToken}` },
	};
	const response = await axios.post(
		baseURL + "/stopstreamingbackend",
		{ userid: window.sessionStorage.getItem("userId") },
		config
	);
	return response;
}

export async function appDetails(appid) {
	const accessToken = window.sessionStorage.getItem("accessToken");
	//      console.log("accessToken window.sessionStorage:", accessToken);
	const config = {
		headers: { Authorization: `Bearer ${accessToken}` },
	};
	const response = await axios.post(baseURL + "/appdetails", { id: appid }, config);
	// console.log("Response APPDETAILS: ",response);
	return response;
}

export async function appList() {
	const accessToken = window.sessionStorage.getItem("accessToken");
	//      console.log("accessToken window.sessionStorage:", accessToken);
	const config = {
		headers: { Authorization: `Bearer ${accessToken}` },
	};
	const response = await axios.get(baseURL + "/applist_dyn", config);
	// console.log("Response APPDETAILS: ",response);

	return response;
}

// function to provide an application id to a streaming instance and retreive a signal when the stream can be established
export async function startApplicationOnServer(appid) {
	const accessToken = window.sessionStorage.getItem("accessToken");
	//      console.log("accessToken window.sessionStorage:", accessToken);
	const config = {
		headers: { Authorization: `Bearer ${accessToken}` },
	};
	const response = await axios.post(
		baseURL + "/streaming/startApplication",
		{ id: appid, userName: window.sessionStorage.getItem("userName") },
		config
	);
	return response;
}

window.stopApplicationOnServer = async function stopApplicationOnServer(appid) {
	const accessToken = window.sessionStorage.getItem("accessToken");
	//      console.log("accessToken window.sessionStorage:", accessToken);
	const config = {
		headers: { Authorization: `Bearer ${accessToken}` },
	};
	const response = await axios.post(
		baseURL + "/streaming/startApplication",
		{ id: "stop", userName: window.sessionStorage.getItem("userName") },
		config
	);
	return response;
};
