import React from "react";
import home from "../images/logos/home-2-line.svg";
import logout from "../images/logos/logout-box-r-line.svg";
import { NavLink } from "react-router-dom";
import FooterItem from "./FooterItem";
import { useTranslation, initReactI18next } from "react-i18next";

/* 
1. --showRecommendation -> App Context
2. --nach AppContext abfragen
2.1 showrecommendationoption in datenbank/API schreiben und wieder rauslesen
3. --websocket änderungen anzeigen oder nicht
3.1 wie bekommt man aus websocket input eine Notifikation/einen zugriff auf den footer hin
3.2 wie/wann deaktivieren wir die Footer Notifikation wieder
4. Zugriff auf DOM und CSS
    wenn recommendation true und websocket neue recommendation dann ändere CSS data theme für footer und füge recommendtion 
    in den DOM ein ( nach library )
*/

export default function Footer() {
	const { t } = useTranslation();
	const footerList = [
		{ destination: "/", id: "button_home", hasImage: home, text: "Home", classes:"button-home-without-recommendations" },
		{ destination: "/library", id: "button_library", text: t("library_button") },
		{ destination: "/community", id: "button_community", text: t("community_button") },
		{ destination: "/login", id: "button_logout", hasImage: logout, text: "Login", classes:"button-logout-without-recommendations" },
	];

	return (
		<div id="footer" className="footer-without-recommendations">
			{footerList.map((footerItem) => (
				<FooterItem
					key={footerItem["id"]}
					id={footerItem["id"]}
					destination={footerItem["destination"]}
					text={footerItem["text"]}
					hasImage={footerItem["hasImage"]}
					classes={footerItem["classes"]}
				/>
			))}
		</div>
	);
}
