import React from "react";
import wifi0 from "./images/wifi0.png";
import wifi1 from "./images/wifi1.png";
import wifi2 from "./images/wifi2.png";
import wifi3 from "./images/wifi3.png";
import wifi4 from "./images/wifi4.png";

export default function Connection(props) {
	const images = [wifi0, wifi1, wifi2, wifi3, wifi4];
	const code = props.code; //   props.code;
	return (
		<div className="widget">
			<img className="widget_img" src={images[code]} alt="cleaning images" />
		</div>
	);
}
