import React, { useEffect, useState } from "react";
import { startApplicationOnServer, appList } from "../features/api-gateway/aws-apigw";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

export default function AppDetails() {
	const { t, i18n } = useTranslation(["translation", "welcome"]);

	function stopOverlay() {
		var overlayDiv = document.getElementById("streamOverlay");

		if (overlayDiv.style.display === "block") {
			overlayDiv.style.display = "none";
		}
	}
	function startStream(id) {
		var serverRes;
		startApplicationOnServer(id).then((res) => {
			var overlayDiv = document.getElementById("streamOverlay");
			overlayDiv.style.display = "block";
			// TODO: handling of statuscode res.status
			serverRes = res.data;
			console.log(res.data);
			console.log("Stream started: ", Number(id));
			console.log(serverRes);
			console.log(JSON.stringify(serverRes));
			try {
				window.startStream(serverRes).then((res) => {
					console.log("startStream executed by client");
				});
			} catch (error) {
				console.error(error);
				//TODO: handle error for non client usage
			}
		});
		window.setTimeout(stopOverlay, 15000);

		return serverRes;
	}

	const [token, setToken] = useState("");
	const [appdata, setAppdata] = useState("");

	const [searchParams] = useSearchParams();
	const id = searchParams.get("id");

	const Backbutton = () => {
		let navigate = useNavigate();
		return (
			<>
				<button className="appdetails-button-back" onClick={() => navigate(-1)}>
					{t("app_details_back")}
				</button>
			</>
		);
	};
	//console.log(id); // 'name'

	useEffect(() => {
		const apps = JSON.parse(localStorage.getItem("Apps"));
		if (apps == null) {
			const response = appList().then((data) => {
				//	console.log("Login Response1: ", data);
				const statuscode = data.data.statusCode;

				if (statuscode === 200) {
					const appdata = data.data.body;
					//		console.log(appdata);
					window.sessionStorage.setItem("applist", JSON.stringify(appdata));
					const apps = {};
					appdata.forEach((element) => {
						//		console.log(element.AppID);
						apps[element.AppID] = element;
					});
					localStorage.setItem("Apps", JSON.stringify(apps));
				}
			});
		}
		//console.log("apps: ", apps);
		//console.log("apps window: ", localStorage.getItem("Apps"));

		//console.log("APPDATA appDetail: ", appdata);
		if (apps !== null) {
			setAppdata(apps[id]);
			//console.log("APPs: ", apps[id]);
		}
		setToken(true);
	}, []);

	return (
		<div className="appdetails-container-content">
			<div className="appdetails-container-content-left">
				<div>
					<img
						className="libaryImage"
						src={
							token
								? require("../images/library/" + appdata.AppID + ".png")
								: require("../images/library/undefined.png")
						}
						alt={appdata.Name}
					></img>
				</div>
				<div>
					<button className="appdetails-button-stream" onClick={() => startStream(id)}>
						{t("app_details_stream")}
					</button>
				</div>
			</div>
			<div className="appdetails-container-content-right">
				<div className="appdetails-container-content-right-header">
					<div className="appdetails-logo">
						<h3 className="appdetails_titel">{appdata.Name}</h3>
					</div>
					<div>
						<Backbutton />
					</div>
				</div>
				<div className="appdetails-description">{appdata.Description}</div>
				<div>{appdata.ControllerSupport}</div>
				<div>
					{appdata.Restrictions ? (
						<div className="appdetails-tags">
							{appdata.Restrictions.map((restriction) => (
								<p key={restriction} className="appdetails-tag">
									{restriction}
								</p>
							))}
						</div>
					) : (
						<></>
					)}
					{appdata.Tags ? (
						<div className="appdetails-tags">
							{appdata.Tags.map((tag) => (
								<p key={tag} className="appdetails-tag">
									{tag}
								</p>
							))}
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	);
}
