import React from "react";
import sunny from "./images/weather/sunny.png";
import few_clouds from "./images/weather/few_clouds.png";
import cloudy from "./images/weather/cloudy.png";
import rain from "./images/weather/rain.png";
import thunder from "./images/weather/thunder.png";
import storm from "./images/weather/storm.png";
import clouds_rain from "./images/weather/clouds_rain.png";
import broken_clouds from "./images/weather/broken_clouds.png";

export default function Weather(props) {
	const images = [sunny, few_clouds, cloudy, rain, thunder, storm, clouds_rain, broken_clouds];
	const code = props.code;
	return (
		<div className="widget">
			<img className="weather" src={images[code]} height="250" alt="cleaning images" />
		</div>
	);
}
