import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../features/cognito/Account";
import { userdata, stopStreamingbackend, appList } from "../features/api-gateway/aws-apigw";

import GL from "../images/profiles/GL.png";
import FS from "../images/profiles/FS.png";
import { LoggedInContext } from "../features/cognito/loggedInHook";
export default function Login(props) {
	const [loggedIn, setLoggedIn] = useContext(LoggedInContext);
	let navigate = useNavigate();
	const usernameRef = useRef();
	const passwordRef = useRef();

	//const [username, setUsername] = useState("floerschs");
	//const [password, setPassword] = useState("floerschs");

	const { getSession, logOut, authenticate } = useContext(AccountContext);

	useEffect(() => {
		getSession().then(() => {
			setLoggedIn(true);
			//userdata();
		});
	});

	function changeLM() {
		//console.log("LM");
		document.getElementById("userName").value = "geisslerlm";
		document.getElementById("userPassword").value = "geisslerlm";
	}
	function changeSF() {
		//console.log("LM");
		document.getElementById("userName").value = "floerschs";
		document.getElementById("userPassword").value = "floerschs";
	}

	const onSubmit = (event) => {
		//console.log(event);
		event.preventDefault();
		//console.log("onSubmit");
		let username = usernameRef.current.value;

		let password = passwordRef.current.value;
		authenticate(username, password)
			.then((data) => {
				//	console.log("Logged in!", data);

				getSession();
				setLoggedIn(true);
				const response = appList().then((data) => {
					//console.log("Login Response1: ", data);
					const statuscode = data.data.statusCode;
					if (window.localStorage.getItem("applist") == null) {
						if (statuscode === 200) {
							const appdata = data.data.body;
							//	console.log(appdata);
							window.localStorage.setItem("applist", JSON.stringify(appdata));
							const apps = {};
							appdata.forEach((element) => {
								//		console.log(element.AppID);
								apps[element.AppID] = element;
							});
							localStorage.setItem("Apps", JSON.stringify(apps));
						}
					}
				});

				userdata().then(() => {
					//console.log(						"image in header: ",						JSON.parse(window.localStorage.getItem("ProfileImageBaseSmall")));

					navigate("/");
				});
			})
			.then()
			.catch((err) => {
				console.error("Failed to login", err);
			});
	};

	const onLogout = (event) => {
		event.preventDefault();
		//console.log("onLogout");
		//console.log("userid: ", window.sessionStorage.getItem("userId"));
		const stopping = stopStreamingbackend().then((data) => {
			//	console.log(data);
		});

		logOut()
			.then((data) => {
				//console.log("Logged out!", data);
				setLoggedIn(false);
				navigate("/");
				//window.location.reload();
			})
			.catch((err) => {
				console.error("Failed to logout", err);
			});
	};

	return (
		<div>
			{loggedIn ? (
				<form onSubmit={onLogout}>
					<button type="submit" className="glow-on-hover">
						Logout
					</button>
				</form>
			) : (
				<div className="login">
					<div>
						<form onSubmit={onSubmit}>
							<div>Login</div>
							<div id="login_username">
								<label htmlFor="username">Username</label>
								<br />
								<input id="userName" ref={usernameRef} type="text"></input>
								<br />
							</div>
							<div id="login_password">
								<label htmlFor="password">Password</label>
								<br />
								<input id="userPassword" type="text" ref={passwordRef}></input>
								<br />
							</div>
							<button type="submit" className="glow-on-hover">
								Login
							</button>
						</form>
					</div>
					<div>
						<button type="button" onClick={changeLM} className="ProfileButton">
							<img className="switchProfileImage" alt="Prod" src={GL} height="200" />
						</button>
						<br></br>
						<br></br>
						<br></br>
						<button type="button" onClick={changeSF} className="ProfileButton">
							<img className="switchProfileImage" alt="Dev" src={FS} height="200" />
						</button>
					</div>
				</div>
			)}
		</div>
	);
}
