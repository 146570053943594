import React, { useEffect, useContext, useState } from "react";
import { userdata } from "../features/api-gateway/aws-apigw";
import { AccountContext } from "../features/cognito/Account";
import { useTranslation, initReactI18next } from "react-i18next";
import LevelBar from "./LevelBar.js";
//import Popup from 'reactjs-popup';

export default function Profile() {
	const { getSession } = useContext(AccountContext);
	const [loggedIn, setLoggedIn] = useState(false);
	const [token, setToken] = useState("");
	const [userInformation, setUserInformation] = useState(undefined);
	const [popUp, setPopUp] = useState(false);
	const { t } = useTranslation();

	let userlevel;
	let username;
	let achievments;
	let friends;
	let profileImage;

	const togglePopUp = () => {
		setPopUp(!popUp);
	};

	useEffect(() => {
		setLoggedIn(JSON.parse(window.sessionStorage.getItem("loggedIn")));
		// ;
		// window.sessionStorage.getItem("favorites")
		// window.sessionStorage.setItem('userData',JSON.stringify(response.data.body.Item));

		// (async () => {
		//     setUserInformation(await userdata());
		// })();
		// setLoggedIn(true);

		//console.log("LS userData; ",window.sessionStorage.getItem("userData"));
		//console.log("LS favorites; ",window.sessionStorage.getItem("favorites"));
		// console.log("userInformation: ",userInformation.body.Item);
		// window.sessionStorage.setItem('userData',JSON.stringify(userdataGet));
		// window.sessionStorage.setItem('favorites',JSON.stringify(userdataGet).favorites  );
		// setLoggedIn(true);
		//console.log("userInformation: ",userInformation);
	}, []);

	//     useEffect(() => {
	//        //  getSession().then(()=> {
	//        //     setLoggedIn(true);
	//        // })
	//         //console.log( "window.sessionStorage UserData:",       window.sessionStorage.getItem("userData"));
	//         (async () => {
	//             setUserInformation(await userdata());
	//             setToken(true);

	//          });

	//   /*       async function getUserInformation() {

	//             const respone = await userdata().then(

	//             );
	//             console.log("Response",response);
	//             const statuscode = respone.status;
	//             //console.log("StatusCode",statuscode);
	//             if(statuscode ===200){
	//             // console.log("Test",response.data.body);
	//             const userdata = respone.data.body.Item;
	//             setUserInformation( JSON.parse(window.sessionStorage.getItem("userData")));
	//             setToken(true);
	//         };
	//         }; */

	// /*         if(!token ){
	//             userInformation();
	//         }
	//         getSession().then(()=> {

	//            //  {{achievments}}
	//            setUserInformation(JSON.parse(window.sessionStorage.getItem("userData")));

	//            setLoggedIn(true);
	//        }) */
	//     },[]);
	//console.log("username:", username);
	//console.log("username (logged):", loggedIn);

	///console.log("userinformation: ", userInformation);
	if (loggedIn) {
		const userdata = JSON.parse(window.sessionStorage.getItem("userData"));
		friends = userdata.Friends;
		profileImage = userdata.ProfileImageBase;
		username = userdata.UserName;
		//console.log("username:", username);
		userlevel = userdata.UserLevel;
		achievments = userdata.UserAchievments;
		//{
		//	achievments.map((d) => console.log("d: ", `../images/achievements/${d}.png`));
		//}
		if (JSON.parse(window.sessionStorage.getItem("userData"))) {
			profileImage = JSON.parse(window.sessionStorage.getItem("userData")).ProfileImageBase;
			//console.log(profileImage);
		}

		//console.log("Userdata: ",JSON.parse(window.sessionStorage.getItem("userData")));
	}
	//console.log("UserID: ", window.sessionStorage.getItem("userId"));
	//   {{friends}}
	//  const achievpic1 = ("../images/achievements/Achievements 1.png");
	//console.log(JSON.parse(window.sessionStorage.getItem("userData")));
	// console.log(userInformation);

	return (
		<div className="profile-container-content">
			{loggedIn ? (
				<>
					<div className="profile-container-content-left">
						<div>
							<div>{username}</div>
							<img
								className="ProfileImage"
								src={`data:image/jpeg;base64,${profileImage}`}
								alt="Red dot"
								key={profileImage}
							/>
						</div>
						<div className="profile-information">
							<div>
								Level: {userlevel}
								<LevelBar key={"userlevel"} done="23" maxLevel="100" />
							</div>
						</div>
					</div>
					<div className="profile-container-content-right">
						<div className="profile-achievements">
							<div>{t("achievements")}</div>
							<div className="profile-achievements-pics">
								{achievments.map((d) => (
									<button
										className="profile-buttons-achievements"
										key={d}
										onClick={togglePopUp}
									>
										<img
											src={require(`../images/achievements/${d}.png`)}
											alt={d}
											key={d}
											className="achievements"
										></img>
									</button>
								))}
							</div>
						</div>
						<div className="profile-friends">
							<div>{t("friends_and_nearby")}</div>
							<div>
								{friends.map((f) => (
									<button className="profile-buttons-friends" key={f} onClick={togglePopUp}>
										<img src={require(`../images/friends/online.png`)} alt={f} key={f} />
									</button>
								))}
							</div>
						</div>
					</div>
					{popUp && (
						<div className="popup">
							<div className="popup-overlay" onClick={togglePopUp}>
								<div className="popup-content">
									<h2>Test</h2>
									<p>test</p>
									<button className="popup-close" onClick={togglePopUp}>
										X
									</button>
								</div>
							</div>
						</div>
					)}
				</>
			) : (
				<div>Login</div>
			)}
		</div>
	);
}
