import React, { useState } from "react";
import LibraryCategoryStrip from "./LibraryCategoryStrip";
import { useTranslation, Trans } from 'react-i18next';

export default function LibraryCategory(props) {
	const { t, i18n} = useTranslation(['translation', 'welcome']);

	return (
		<div className="library-container-category-apps">
			<LibraryCategoryStrip 
				list={props.displayedAppList.filter((data) => {
					return data.Type.includes("food");
				})} name={t("category_food")}/>
			<LibraryCategoryStrip 
				list={props.displayedAppList.filter((data) => {
					return data.Type.includes("game");
				})} name={t("category_games")}/>
			<LibraryCategoryStrip 
				list={props.displayedAppList.filter((data) => {
					return data.Type.includes("parking");
				})} name={t("category_parking")}/>
			</div>
	);
}