import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { useTranslation, initReactI18next } from "react-i18next";

Amplify.configure(awsExports);

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.use(HttpApi)
	.init({
		// the translations
		// (tip move them in a JSON file and import them,
		// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)

		// Choose language, right now: from index.html html tag
		lng: document.querySelector("html").lang,
		fallbackLng: "en",

		backend: {
			loadPath: "/assets/locales/{{lng}}/translation.json",
		},
		react: { useSuspense: false },
	});

// function App() {
//   const { t } = useTranslation();

//   return <h2>{t('welcome_to_react')}</h2>;
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
