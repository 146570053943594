import "./App.css";
import React from "react";
import { HashRouter } from "react-router-dom";
//import { toast } from 'aws-amplify';
//import { Android } from 'aws-amplify';
//import { Button } from '@aws-amplify/ui-react';
//import AppComponents from './AppComponents';
//import AppRouter from './layout/AppRouter';
import Footer from "./layout/Footer";
import Header from "./layout/Header";
import { Account } from "./features/cognito/Account";
import Status from "./features/cognito/Status";
//import { w3cwebsocket as W3CWebSocket } from 'websocket';
import useLocalStorage from "use-local-storage";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Library from "./pages/Library";
import Store from "./pages/Store";
//import Friends from './pages/Friends';
import Settings from "./pages/Settings";
import Profiles from "./pages/Profile";
import Login from "./pages/Login";
import AppDetails from "./pages/AppDetails";
import Community from "./pages/Community";
import { AccountContext } from "./features/cognito/Account";
import Recommendations from "./pages/Recommendations";
import RecommendationsOverlay from "./pages/RecommendationsOverlay";
//import mqttHook from "./features/mqtt/mqttHook";
import { WebsocketContext, WebsocketProvider } from "./features/mqtt/WebsocketContext";
import RecommendationsComponent from "./pages/RecommendationsComponent";
import { LoggedInContext, LoggedInContextProvider } from "./features/cognito/loggedInHook";
import { SettingsHookProvider } from "./features/cognito/SettingsHook";
//const { getRecommendationOption } = useContext(AccountContext);

//const client = new W3CWebSocket('wss://2ak9nq9cn8.execute-api.eu-central-1.amazonaws.com/production');

/* client.onopen = () => {
	console.log('WebSocket Client Connected');
	console.log(client);
	client.send({ action: 'sendMessages', a2: 'gsdfsd' });
};

client.onmessage = (message) => {
	console.log(message);
	// Auswertung der Daten, Rendern der Symbole
	// Use state/effect
	/*if(getRecommendationOption()){
    alert(message.data);
  }
};

client.onerror = (error) => {
	console.log(error);
}; */

export default function App() {
	const [theme, setTheme] = useLocalStorage("theme", process.env.REACT_APP_DEFAULT_THEME);
	//mqttHook();
	const callback = (theme) => {
		setTheme(theme);
	};

	return (
		<div className="grid-container" data-theme={theme}>
			<Account>
				<LoggedInContextProvider>
					<SettingsHookProvider>
						<WebsocketProvider>
							<Status />
							<HashRouter>
								<Header />
								<div id="overlay">
									<RecommendationsOverlay></RecommendationsOverlay>
								</div>
								<div id="streamOverlay">
									Stream is starting... <div id="html-spinner"></div>
								</div>
								<Routes>
									<Route exact path="/" element={<Home />}></Route>
									<Route exact path="/library" element={<Library />}></Route>
									<Route exact path="/app" element={<AppDetails />}></Route>

									<Route exact path="/store" element={<Store />}></Route>
									<Route
										exact
										path="/recommendations"
										element={<Recommendations />}
									></Route>
									<Route exact path="/community" element={<Community />}></Route>
									<Route
										exact
										path="/settings"
										element={<Settings callback={callback} />}
									></Route>
									<Route exact path="/profile" element={<Profiles />}></Route>
									<Route exact path="/login" element={<Login />}></Route>
								</Routes>
								<Footer />
							</HashRouter>
						</WebsocketProvider>
					</SettingsHookProvider>
				</LoggedInContextProvider>
			</Account>
		</div>
	);
}
