import React from "react";
import LibraryComponent from "./LibraryComponent";

export default function LibraryCategoryStrip(props) {
    const name = props.name;
    const appList = props.list;
	return (
        <div className="library-category-strip">
            <div>
                <h3>{name}</h3>
            </div>
            <div className="library-category-strip-horizontal">
            {appList.map((app) => (
						
						<LibraryComponent
							key={app["AppID"]}
							id={app["AppID"]}
							img={require("../images/library/" + app["AppID"] + ".png")}
							name={app["Name"]}
						/>
					))}
            </div>
        </div>
	);
}
