import { createContext, useState, useRef, useEffect, useContext } from "react";
import { SettingsHook } from "../cognito/SettingsHook";

const WebsocketContext = createContext(false, null, () => {});

//                                            ready, value, send

// Make sure to put WebsocketProvider higher up in
// the component tree than any consumers.
const WebsocketProvider = ({ children }) => {
	const [isReady, setIsReady] = useState(false);
	const [val, setVal] = useState(null);
	const [recommendations, setRecommendations] = useState(null);
	const [
		language,
		setlanguage,
		showRecommendations,
		setShowRecommendations,
		recommendationsTab,
		setRecommendationsTab,
		streamingMode,
		setStreamingMode,
	] = useContext(SettingsHook);

	const ws = useRef(null);

	if (recommendations) {
		console.log(showRecommendations);
		console.log(recommendationsTab);
		if (showRecommendations) {
			if (recommendationsTab) {
				//navigate(`/recommendations`);
			} else {
				document.getElementById("overlay").style.display = "block";
			}
		}

		//
	}

	useState(() => {
		const socket = new WebSocket("wss://2ak9nq9cn8.execute-api.eu-central-1.amazonaws.com/production");

		socket.onopen = () => {
			setIsReady(true);
			console.log(socket);
		};

		socket.onclose = () => setIsReady(false);
		socket.onmessage = (event) => {
			//	console.log("showRecommendations: ", showRecommendations);
			//	console.log("recommendationsTab: ", recommendationsTab);

			setVal(event.data);
			//console.log("Event: ", event);
			//console.log("Event data: ", event.data);
			//if (showRecommendations) {

			var data = JSON.parse(event.data);
			//console.log("parse data: ", data);
			if (data.recommendations && data.recommendations.length > 0) {
				//		console.log("showRecommendations: ", showRecommendations);
				//			console.log("recommendationsTab: ", recommendationsTab);
				//setPrevData(data);
				//console.log("REC DATA: ", data.recommendations);
				console.log("Recommendations:", data.recommendations);
				window.sessionStorage.setItem("recommendations", JSON.stringify(recommendations));
				setRecommendations(data.recommendations);

				///Navigate({ to: "/recommendation", state: recommendations });
			}
			//}
		};
		console.log("WebsocketContext connected!");
		ws.current = socket;

		return () => {
			socket.close();
		};
	}, []);

	const ret = [
		isReady,
		val,
		setVal,
		recommendations,
		setRecommendations,
		ws.current?.send.bind(ws.current),
	];

	return <WebsocketContext.Provider value={ret}>{children}</WebsocketContext.Provider>;
};
export { WebsocketProvider, WebsocketContext };
