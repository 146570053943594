import React, { useEffect, useContext, useState } from "react";
import { AccountContext } from "../features/cognito/Account";

import { NavLink } from "react-router-dom";
//import { useNavigate } from "react-router-dom";
import Clock from "../widgets/Clock";
import Connection from "../widgets/Connection";
import BatteryCar from "../widgets/BatteryCar";
import BatteryController from "../widgets/BatteryController";
import Weather from "../widgets/Weather";

import settingsImg from "../images/logos/settings-5-fill.svg";
import profile_undefined from "../widgets/images/profile_undefined.png";
import { WebsocketContext } from "../features/mqtt/WebsocketContext";
import { useTranslation } from "react-i18next";
//import mqttHook from "../features/mqtt/mqttHook";

export default function Header() {
	const [isReady, val] = useContext(WebsocketContext);
	//let navigate = useNavigate();
	const { getSession } = useContext(AccountContext);
	const [loggedIn, setLoggedIn] = useState(false);
	//const [message, setMessage] = useState(mqttHook());
	const { t } = useTranslation();
	const [connectionCode, setConnectionCode] = useState(0);
	const [weatherCode, setWeatherCode] = useState(0);
	const [carBattery, setCarBattery] = useState(0);
	const [controllerBattery, setControllerBattery] = useState(0);
	//var connectionCode = 0;
	//var weatherCode = 0;
	//var carBattery = 0;
	//var controllerBattery = 0;
	//var i = 0;

	useEffect(() => {
		var recommendations;

		if (val) {
			var data = JSON.parse(val);
			//console.log("VAL: ", data);
			//console.log("PREVVAL: ", prevData);
			if (data.carContext) {
				setConnectionCode(data.carContext.connection);
				setWeatherCode(data.carContext.weather);
				setCarBattery(data.carContext.carBattery);
				setControllerBattery(data.carContext.controllerBattery);
			}
			//console.log("VAL: ", data.recommendations);
			/* 			if (data.recommendations && data.recommendations.length > 0) {
				recommendations = data.recommendations;
				//setPrevData(data);
				//console.log("REC DATA: ", data.recommendations);
				//console.log("Recommendations:", recommendations);
				window.sessionStorage.setItem("recommendations", JSON.stringify(recommendations));
				document.getElementById("overlay").style.display = "block";
				//navigate(`/recommendations`);

				///Navigate({ to: "/recommendation", state: recommendations });
			} */
		}
	}, [val]); // make sure to include send in dependency array
	//if (ready && val) {

	//}
	/* 	if (mqttHook()) {
		//setMessage(mqttHook());
		const data = mqttHook();
		//console.log("message: ", data);
		if (data.carContext) {
			connectionCode = data.carContext.connection;
			weatherCode = data.carContext.weather;
			carBattery = data.carContext.carBattery;
			controllerBattery = data.carContext.controllerBattery;
		}
		if (data.recommendations && data.recommendations.size > 0) {
			recommendations = data.recommendations;
			Navigate({ to: "/recommendation", state: recommendations });
		}
		//setMessage(mqttHook());
		//if (JSON.parse(mqttHook())) {
		//	const message = JSON.parse(mqttHook());
		//}
		//const message = mqttHook();
		//setMessage(message);
		//console.log('Mqtt: ', message);
		//console.log('carContext: ', message.carContext);
	} */
	/* 	if (mqttHook()) {
		//setMessage(mqttHook());
		if (JSON.parse(mqttHook())) {
			const carContextMessage = JSON.parse(message);
			console.log('carContextMessage: ', carContextMessage);
			if (carContextMessage.carContext) {
				console.log('Mqtt: ', carContextMessage);
				console.log('carContext: ', carContextMessage.carContext);
				//connection = message.carContext.connection;
			}
		}
	} */

	useEffect(() => {
		getSession().then(() => {
			setLoggedIn(true);
		});
	});

	var profileImage = null;
	if (loggedIn) {
		//	console.log("loggedIn: ", window.localStorage.getItem("ProfileImageBaseSmall"));

		//console.log("loggedIn userData:", JSON.parse(window.sessionStorage.getItem("userData")));
		if (JSON.parse(window.localStorage.getItem("ProfileImageBaseSmall"))) {
			//	console.log("loggedIn userData");
			profileImage = JSON.parse(window.localStorage.getItem("ProfileImageBaseSmall"));
		}
		//console.log(profileImage);
	}
	//console.log(window.sessionStorage.getItem('idToken'));

	return (
		<div id="header">
			<div id="widgets">
				<Connection code={connectionCode} />
				<BatteryController code={controllerBattery} />
				<BatteryCar code={carBattery} />
				<Weather code={weatherCode} />
			</div>
			<div id="date">
				<Clock />
			</div>
			<div id="headermenu">
				<NavLink to="/profile">
					<div className="header_buttons">
						<div className ="headerLink" id="button_profile">
							<img
								id="widget_profileImage"
								alt="Profile"
								src={
									JSON.parse(window.sessionStorage.getItem("userData"))
										? `data:image/jpeg;base64,${profileImage}`
										: profile_undefined
								}
							/>
						</div>

						<div  id="profile_text">{t("profile_button")}</div>
					</div>{" "}
				</NavLink>
				<NavLink to="/settings">
					<div className ="headerLink" id="button_settings">
						<img id="widget_settingsGear" alt="cleaning images" src={settingsImg} />
					</div>
				</NavLink>
			</div>
		</div>
	);
}
