import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "../features/cognito/Account";
import HomeComponent from "./HomeComponent";

import { useTranslation, initReactI18next } from "react-i18next";
import RecommendationsComponent from "./RecommendationsComponent";
import { LoggedInContext } from "../features/cognito/loggedInHook";
import { WebsocketContext } from "../features/mqtt/WebsocketContext";
import { SettingsHook } from "../features/cognito/SettingsHook";
export default function Recommendations(props) {
	const [isReady, val, setVal, recommendations, setRecommendations] = useContext(WebsocketContext);
	const [loggedIn, setLoggedIn] = useContext(LoggedInContext);
	const [
		language,
		setlanguage,
		showRecommendations,
		setShowRecommendations,
		recommendationsTab,
		setRecommendationsTab,
		streamingMode,
		setStreamingMode,
	] = useContext(SettingsHook);
	const { t } = useTranslation();
	var recommendationList = [];
	recommendationList = JSON.parse(window.sessionStorage.getItem("recommendations"));
	if (recommendations) {
		//	console.log(			"window.sessionStorage.getItem(recommendations): ",			window.sessionStorage.getItem("recommendations")		);
		recommendationList = recommendations;
		//recommendationItems = JSON.parse(window.sessionStorage.getItem("recommendations"));
		//console.log("RecommendationsObject: ", recommendationItems);
	}
	useEffect(() => {}, [loggedIn]);

	//console.log("RecommendationsObject: ", recommendationList);
	//window.sessionStorage.removeItem("recommendations");

	return (
		/* loggedIn isn't working , but why */
		<div className="recommendations-grid">
			{loggedIn & (recommendationList != undefined || !showRecommendations) ? (
				<RecommendationsComponent recommendationList={recommendations}></RecommendationsComponent>
			) : (
				<div className="recommendations-empty">{t("recommendations_empty")}</div>
			)}
		</div>
	);
}
