import { createContext, useState } from "react";

const SettingsHook = createContext(false, null, () => {});

const SettingsHookProvider = ({ children }) => {
	const [language, setlanguage] = useState(false);
	const [showRecommendations, setShowRecommendations] = useState(true);
	const [recommendationsTab, setRecommendationsTab] = useState(false);
	const [streamingMode, setStreamingMode] = useState(false);

	const ret = [
		language,
		setlanguage,
		showRecommendations,
		setShowRecommendations,
		recommendationsTab,
		setRecommendationsTab,
		streamingMode,
		setStreamingMode,
	];

	return <SettingsHook.Provider value={ret}>{children} </SettingsHook.Provider>;
};
export { SettingsHookProvider, SettingsHook };
