import React, { useState, useContext, useEffect, createContext } from "react";
import { AccountContext } from "../features/cognito/Account";
import Store from "../pages/Store";
import Recommendations from "./Recommendations";

import { useTranslation, Trans } from "react-i18next";
import { SettingsHook } from "../features/cognito/SettingsHook";

export default function Settings({ callback }) {
	const [loggedIn, setLoggedIn] = useState(false);
	const { getSession, setRecommendationOption, getRecommendationOption } = useContext(AccountContext);
	const [isShown, setIsShown] = useState(getRecommendationOption);
	const { t, i18n } = useTranslation(["translation", "welcome"]);
	const [
		language,
		setlanguage,
		showRecommendations,
		setShowRecommendations,
		recommendationsTab,
		setRecommendationsTab,
		streamingMode,
		setStreamingMode,
	] = useContext(SettingsHook);

	useEffect(() => {
		getSession().then(() => {
			setLoggedIn(true);
		});

		const RecommendationOption = getRecommendationOption();

		//checkbox.checked = isShown;
	});
	let theme = window.sessionStorage.getItem("theme")?.replace(/['"]+/g, "");
	const checkbox = document.getElementById("checkbox");

	// fallback if the theme is not set we set the default
	if (!theme) theme = process.env.REACT_APP_DEFAULT_THEME;

	// calback that will send the new theme information to the parent
	const handleCallback = (trigger) => {
		callback(trigger);
	};

	const changeLanguage = async (code) => {
		setlanguage(code);
		await i18n.changeLanguage(code);

		let recommendationButton = document.getElementById("button_recommendation_text");
		if (recommendationButton) {
			recommendationButton.innerText = "";
			const text = document.createTextNode(t("recommendation_button"));
			recommendationButton.append(text);
		}
	};

	const setRecommendationsTabFun = (event) => {
		let value = event.target.checked;
		//setRecommendationsTab(value);

		handleClick(event);
	};
	const setShowRecommendationsOptions = (event) => {
		let value = event.target.checked;
		//console.log(value);
		setShowRecommendations(value);
		//console.log(showRecommendations);
		//handleClick(event);
	};

	/* Funktion zum Anzeigen/Nichtanzeigen der Recommendations*/
	const handleClick = (event) => {
		//setIsShown(event.target.checked);
		if (showRecommendations) {
			setRecommendationsTab(event.target.checked);
			//console.log(event.target.checked);
			let footer = document.getElementById("footer");
			let home = document.getElementById("button_home");
			let logout = document.getElementById("button_logout");
			let store = document.getElementById("button_community_navLink");
			//TODO: General Footer Array stored as useState / useContext
			// add element to array
			// delete element from array
			if (!recommendationsTab) {
				const recommendation_div = document.createElement("div");
				recommendation_div.setAttribute("id", "button_recommendation");
				recommendation_div.setAttribute("class", "footer_buttons");

				const recommendation_navlink = document.createElement("a");
				recommendation_navlink.setAttribute("id", "button_recommendation_navLink");
				recommendation_navlink.setAttribute("href", "#/recommendations");
				//recommendation_navlink.setAttribute("onClick", "button_active");
				const button_text = document.createElement("div");
				button_text.setAttribute("id", "button_recommendation_text");
				button_text.setAttribute("class", "button_texts");
				const text = document.createTextNode(t("recommendation_button"));
				button_text.append(text);
				recommendation_navlink.append(recommendation_div);
				recommendation_div.append(button_text);
				footer.insertBefore(recommendation_navlink, store);
				footer.classList.remove("footer-without-recommendations");
				footer.classList.add("footer-with-recommendations");
				home.classList.remove("button-home-without-recommendations");
				home.classList.add("button-home-with-recommendations");
				logout.classList.remove("button-logout-without-recommendations");
				logout.classList.add("button-logout-with-recommendations");
			} else {
				let button_recommendation = document.getElementById("button_recommendation_navLink");
				if (button_recommendation) button_recommendation.remove();
				footer.classList.remove("footer-with-recommendations");
				footer.classList.add("footer-without-recommendations");
				home.classList.remove("footer-with-recommendations");
				home.classList.add("button-home-without-recommendations");
				logout.classList.remove("footer-with-recommendations");
				logout.classList.add("button-logout-without-recommendations");
			}
		}
	};

	return (
		<div className="library-container ">
			{loggedIn ? (
				<div className="settings-grid">
					{/* // <form className="settings-grid"> */}
					{/* Headline */}
					<div className="settings-headline">{t("settings")}</div>

					{/* Recommendations */}
					<div className="settings-recommendations-grid">
						<label>{t("show_recommendations")}</label>
						<div className="settings-recommendations-subgrid">
							<div className="wrap">
								<div className="show-recommendations-slider">
									<label className="switch">
										<input
											className="slider"
											type="checkbox"
											onChange={(event) => setShowRecommendationsOptions(event)}
											checked={showRecommendations}
										></input>
										<span className="slider round"></span>
									</label>
								</div>
							</div>
						</div>
					</div>
					<div className="settings-recommendations-grid">
						<label>{t("recommendations_tab")}</label>
						<div className="settings-recommendations-subgrid">
							<div className="wrap">
								<div className="show-recommendations-slider">
									<label className="switch">
										<input
											className="slider"
											type="checkbox"
											onChange={(event) => setRecommendationsTabFun(event)}
											checked={recommendationsTab}
										></input>
										<span className="slider round"></span>
									</label>
								</div>
							</div>
						</div>
					</div>
					{/* Language */}
					<div className="settings-language-grid">
						<label>{t("language")}</label>
						<div className="settings-language-subgrid-de">
							<button
								className="settings-button-language-de"
								type="button"
								onClick={() => changeLanguage("de")}
							>
								{t()}
							</button>
						</div>
						<div className="settings-language-subgrid-en">
							<button
								className="settings-button-language-en"
								type="button"
								onClick={() => changeLanguage("en")}
							>
								{t()}
							</button>
						</div>
					</div>

					{/* App Style */}
					<div className="settings-appstyle-grid">
						<label>{t("app_style")}</label>
						<div className="settings-appstyle-subgrid-1">
							<button
								className="settings-button-appstyle-1"
								onClick={() => handleCallback("orange")}
							></button>
						</div>
						<div className="settings-appstyle-subgrid-2">
							<button
								className="settings-button-appstyle-2"
								onClick={() => handleCallback("green")}
							></button>
						</div>
						<div className="settings-appstyle-subgrid-3">
							<button
								className="settings-button-appstyle-3"
								onClick={() => handleCallback("default")}
							></button>
						</div>
					</div>

					{/* Graphics */}
					<div className="settings-graphics-grid">
						<label className="settings-graphics-text-front">{t("quality")}</label>
						<button key={"graph1"} className="settings-graphics-button-1">
							1
						</button>
						<button key={"graph2"} className="settings-graphics-button-2">
							2
						</button>
						<button key={"graph3"} className="settings-graphics-button-3">
							3
						</button>
						<button key={"graph4"} className="settings-graphics-button-4">
							4
						</button>
						<button key={"graph5"} className="settings-graphics-button-5">
							5
						</button>
						<label className="settings-graphics-text-end">{t("performance")}</label>
					</div>
					{/* </form> */}
				</div>
			) : (
				<div>Login</div>
			)}
		</div>
	);
}
