import React, { useState } from "react";

const LevelBar = ({ done, maxLevel }) => {
    
  const [style, setStyle] = useState({});
  
  setTimeout(() => {
    const newStyle = {
        opacity: 1,
        width: `${(done / maxLevel) * 100}%`
    }
    setStyle(newStyle);
  },0 );

  return (
    <div className="levelbar">

      <div className="levelbar-done" style={style}>
      {(done / maxLevel) * 100} %    
      </div>
      <div className="levelbar-xp">
        {done}/{maxLevel}
      </div>
    </div>
  );
};

export default LevelBar;
