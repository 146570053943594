import React from "react";
import AppComponents from "./AppComponents";

export default function RecommendationsComponent(props) {
	var recommendations;
	if (props.recommendationList) {
		recommendations = props.recommendationList;
	}
	//recommendations = ["79709895", "00007"];
	return recommendations.map((recommendationList) => (
		//               apps.indexOf(app)

		<AppComponents
			img={require("../images/library/" + recommendationList + ".png")}
			key={recommendationList}
			id={recommendationList}
			className="libraryImage"
		/>
	));
}
