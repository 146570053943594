import React, { useContext, useEffect, useState } from "react";
import { AccountContext } from "../features/cognito/Account";
import LibraryComponent from "./LibraryComponent.js";
import LibraryCategory from "./LibraryCategory";
import { appList as getAppListFromAws } from "../features/api-gateway/aws-apigw";
import { useTranslation, initReactI18next } from "react-i18next";
import { applyDefaults } from "@aws-amplify/ui";
import { ConsoleLogger } from "@aws-amplify/core";
import useLocalStorage from "use-local-storage";

export default function Library() {
	const [loggedIn, setLoggedIn] = useState(false);
	const { getSession } = useContext(AccountContext);
	const [appList, setAppList] = useState([]);
	const [displayedAppList, setDisplayedAppList] = useState([]);
	const [viewSwitch, setViewSwitch] = useLocalStorage(false);
	const { t } = useTranslation();
	const [filterInput, setFilterInput] = useState("");

	useEffect(() => {
		getSession().then(() => {
			setLoggedIn(true);
		});
	});

	const handleSearch = (event) => {
		let value = event.target.value.toLowerCase();
		const filteredAppList = appList.filter((data) => {
			return data.Type.toLowerCase().includes(value) || data.Name.toLowerCase().includes(value);
		});
		setDisplayedAppList(filteredAppList);
		setFilterInput(value);
	};

	const showCategories = (event) => {
		let value = event.target.checked;
		setViewSwitch(value);
	};

	useState(() => {
		async function getAppList() {
			//console.log(window.sessionStorage.getItem("applist") == null);
			if (window.localStorage.getItem("applist") == null) {
				const response = await getAppListFromAws();
				const statuscode = response.data.statusCode;

				if (statuscode === 200) {
					const appdata = response.data.body;
					window.localStorage.setItem("applist", JSON.stringify(appdata));
					appdata.forEach((element) => {
						if (element.Path) {
							//	console.log(element);
							setAppList((appList) => [...appList, element]);
							setDisplayedAppList((displayedAppList) => [...displayedAppList, element]);
						}
					});
				}
			} else {
				//console.log(window.sessionStorage.getItem("applist"));
				const appdata = JSON.parse(window.localStorage.getItem("applist"));
				appdata.forEach((element) => {
					if (element.Path) {
						//	console.log(element.Path);
						setAppList((appList) => [...appList, element]);
						setDisplayedAppList((displayedAppList) => [...displayedAppList, element]);
					}
				});
			}
		}
		getAppList();
	});

	return (
		<div className="library-container">
			<div className="library-container-filter">
				<div>
					<input
						id="library-container-filter-field"
						type="text"
						onChange={(event) => handleSearch(event)}
						placeholder={t("filter_label")}
					></input>
				</div>
				<div className="library-checkbox-filter">
					<label className="switch">
						<input
							className="slider"
							/*id="checkbox-filter"*/
							type="checkbox"
							onChange={(event) => showCategories(event)}
							checked={viewSwitch}
						></input>
						<span className="slider round"></span>
					</label>
					<label> {t("filter_category_label")}</label>
				</div>
			</div>

			{/* 
			
			*/}
			{loggedIn ? (
				!viewSwitch ? (
					<div className="library-container-apps">
						{displayedAppList.map((app) => (
							<LibraryComponent
								key={app["AppID"]}
								id={app["AppID"]}
								img={require("../images/library/" + app["AppID"] + ".png")}
								name={app["Name"]}
							/>
						))}
					</div>
				) : (
					<LibraryCategory displayedAppList={displayedAppList} />
				)
			) : (
				<div> Login</div>
			)}
		</div>
	);
}
