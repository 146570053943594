import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "../features/cognito/Account";

export default function Store() {
	const [loggedIn, setLoggedIn] = useState(false);
	const { getSession } = useContext(AccountContext);
	useEffect(() => {
		getSession().then(() => {
			setLoggedIn(true);
		});
	});

	return <div className="subgrid-container-pages">{loggedIn ? <h1>Store</h1> : <div>Login</div>}</div>;
}
