import React from "react";
import { Link } from "react-router-dom";

//onClick={()=> handleOnClick}
export default function LibraryComponent(props) {

	const appid = props.id;

	return (
		<Link
			to={{
				pathname: "/app",
				search: "id=" + props.id,
			}}>
			<div className="libraryItem">
				<img className="libaryImage" src={props.img} alt={props.name}></img>
				{<p className="game-name">{props.name}</p>}
			</div>
		</Link>
	);
}
